import { Notify } from "quasar";

export default {
  showToast: (message, color) => {
    Notify.create({
      message,
      color,
      timeout: 1500,
      type:color,
      position: 'top-right'
    });
  },
};
