<template>
  <div style="background-color: #fff">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { setCssVar } from "quasar";
import tenantsApi from "./api/tenants/tenants.api";
export default defineComponent({
  name: "App",
  data() {
    return {
      reloaded: false,
    };
  },
  mounted() {
    
    
  },
  methods: {

  },
});
</script>
