<template>
  <q-page class="q-ma-sm">
    <div v-if="this.$route.params.id">
      <div class="row">
        <div class="col-3">
          <q-avatar rounded size="70px">
            <img :src="loja?.LOGO || defaultLogo" />
          </q-avatar>
        </div>
        <div class="col-9">
          <span class="text-h6">{{ object?.LOJA }}</span> <br />
          <span>{{ object?.WHATSAPP }}</span> <br />
        </div>
      </div>
      <div class="text-center">
        <a
          :href="`https://minipdv.lineasoft.com.br/#/${object.NOME_ONLINE}/catalogo`"
          target="_blank"
          >Catalógo de produtos da loja</a
        >
      </div>
      <br />

      <q-card>
        <q-card-section>
          <strong>Detalhes da venda </strong> <br />
          <br />
          <span> Cliente: {{ object.CLIENTE }} </span> <br />
          <span> Data: {{ dateFormat(object?.DATA) }} </span> <br />
          <strong v-if="object.CONDICIONAL" class="text-negative">
            Venda condicional ate {{ object.PRAZO_CONDICIONAL }} <br />
          </strong>
          Nº: {{ object.HASH }}
          <br />
          <br />
          <strong>Produtos</strong>
          <q-list
            separator
            v-for="produto in object.produtosVendas"
            :key="produto.ID"
          >
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label
                  >{{ parseFloat(produto.QUANTIDADE).toFixed(2) }}x
                  {{ produto.NOME }}</q-item-label
                >
                <q-item-label>R${{ produto.VALOR }}</q-item-label> <q-space />
                <q-item-label class="text-bold"
                  >TOTAL R${{ produto.TOTAL }}</q-item-label
                >
              </q-item-section>
            </q-item>
            <q-separator />
          </q-list>

          <br />

          <span>Sub Total R$: {{ object.SUB_TOTAL }}</span> <br />
          <span class="text-negative">Desconto R$: {{ object.DESCONTO }}</span
          ><br />
          <span class="text-positive">Total R$: {{ object.TOTAL }}</span> <br />
          <span>Venda {{ object.TIPO_PAGAMENTO }}</span
          ><span v-if="object.TIPO_PAGAMENTO != 'À VISTA'">
            em {{ object.PARCELAS }}x</span
          >
          <br />
          <span>{{ object.PAGO ? "Venda paga" : "Venda em aberto" }}</span>
          <br />
        </q-card-section>
      </q-card>
      <br />
      <div class="text-center">
        <a
          href="http://lineasoft.com.br/mini-pdv"
          target="_blank"
          rel="noopener noreferrer"
          >Conheça nosso sistema de vendas Mini PDV</a
        >
      </div>
    </div>
    <div v-else class="text-center">
      <q-card>
        <q-card-section>
          <span class="text-h5"
            >Olá, seu pedido não foi encontrado. Fale com a loja responsável
            pelo pedido e tente novamente usando um novo link</span
          >
        </q-card-section>
        <q-card-section>
          <div class="text-center">
            <a
              href="http://lineasoft.com.br/mini-pdv"
              target="_blank"
              rel="noopener noreferrer"
              >Conheça nosso sistema de venda Mini PDV</a
            >
          </div>
        </q-card-section>
      </q-card>
      <br />
    </div>
  </q-page>
</template>
<script>
import formaters from "src/helpers/formaters";
import pedidosApi from "../../api/pedidos/pedidos.api";
import ShowToastMixin from "../../mixins/notify";
export default {
  components: {},
  name: "Pedido",
  data() {
    return {
      object: {
        TOTAL: 0,
        PAGO: 1,
        DATA: "",
        ID_CLIENTE: "",
        TIPO_PAGAMENTO: "À VISTA",
        HASH: "",
        produtosVendas: [],
        TOTAL: 0,
        CLIENTE: "",
        SUB_TOTAL: 0,
        DESCONTO: 0,
        PARCELAS: 1,
        VALOR_PARCELA: 0,
        PIX: 0,
        DINHEIRO: 0,
        CARTAO_CREDITO: 0,
        CARTAO_DEBITO: 0,
        PARCELAS_CARTAO: 1,
        CONDICIONAL: false,
        PRAZO_CONDICIONAL: "",
      },
      tableName: "vendas",
      clientes: [],
      edit: false,
      categorias: ["À VISTA", "CREDIÁRIO", "DUPLICATA"],
      produtos: [],
      showModal: {
        produtos: false,
        parcelas: false,
        cliente: false,
        troco: false,
        clientes: false,
      },
      isMobile: false,
      loading: false,
      parcelas: [],
      limite: {
        valor: 0,
        prazo: 14,
      },
      showClientes: false,
      loja: JSON.parse(localStorage.getItem("loja")),
      valorPago: "",
      troco: 0,
    };
  },
  mixins: [ShowToastMixin],
  async created() {
    if (this.$route.params.id) {
      this.edit = true;
      await this.getVenda();
      await this.getProdutosVenda();
    }
  },
  computed: {
    defaultLogo() {
      return require("../../../public/icons/icon-512x512.png");
    },
  },
  methods: {
    async getProdutosVenda() {
      this.loading = true;
      console.log(this.object);

      await pedidosApi
        .getProdutosVenda(this.object.HASH)
        .then(async (result) => {
          result.data.forEach((produto) => {
            this.object.produtosVendas.push({
              QUANTIDADE: produto.QUANTIDADE,
              NOME: produto.NOME,
              VALOR: produto.VALOR,
              TOTAL: produto.QUANTIDADE * produto.VALOR,
            });
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);

          ShowToastMixin.showToast(
            "Houve um erro ao buscar seus produtos",
            "negative"
          );
          this.loading = false;
        });
    },

    async getVenda() {
      await pedidosApi.getByVendaById(this.$route.params.id).then((result) => {
        if (result?.data) {
          this.object = result.data[0];
          this.object.produtosVendas = [];
          //this.getProdutosVenda()
        }
      });
    },
    dateFormat(date) {
      return formaters.receiptDate(date);
    },
  },
};
</script>
