<template>
  <div style="max-height: min-content; font-size: 12px" class="q-ma-sm">
    <div class="text-left q-ml-sm" id="recibo">
      <q-img
        src="../../../../public/icons/icon-512x512.png"
        style="height: 100px; max-width: 100px"
        spinner-color=""
      />
      <p>
        MINI PDV <br />
        SISTEMA DE CONTROLE DE VENDAS<br />
        DOCUMENTO NAO FISCAL
      </p>
      <div class="text-h6">COMPROVANTE DE AJUSTE DE ESTOQUE</div>
      DATA AJUSTE: {{ dateFormat(recibo.DATA) }}
      <div v-if="recibo.DESCRICAO">
        DESCRIÇÃO: <strong>{{ recibo.DESCRICAO }}</strong>
      </div>
      <p>
        ================================================================================
      </p>
      PROD. ANTERIOR NOVO <br />
      ----------------------------------------------------------------------------------------------------------------------------------------------------------------
      <div v-for="produto in recibo.produtosBalanco" :key="produto">
        <strong>{{ produto.PRODUTO }}</strong> | AJUSTADO DE <strong>{{ produto.QUANTIDADE_ATUAL }}</strong> PARA
        <strong>{{ produto.NOVA_QUANTIDADE }}</strong>
      </div>
      <p>
        ================================================================================
      </p>
      <strong>ITEMS AJUSTADOS{{ recibo?.produtosBalanco?.length }}</strong>
      <br />
      {{ recibo.HASH }}
    </div>
  </div>
</template>
<script>
import formaters from "../../../helpers/formaters";
export default {
  name: "ReciboPage",
  data() {
    return {
      recibo: {},
      logoPath: "",
    };
  },
  mounted() {
    this.recibo = JSON.parse(this.$route.query.data);
    this.printPage();
  },
  methods: {
    printPage() {
      window.print();
    },
    dateFormat(date) {
      return formaters.receiptDate(date);
    },
  },
};
</script>
