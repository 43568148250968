import axios from "axios";
const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/pedidos`;

export default {
  getProdutosVenda: async (hash) => {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/hash/${hash}`,      
      headers: headers(),
    });

    return response.data;
  },

  getByVendaById: async (id) => {
    const response = await axios({
      method: "POST",
      url: `${baseUrl}/id/${id}`,      
      headers: headers(),
    });

    return response.data;
  },
};

const headers = () => {
  const headersJson = {
    Authorization: "Basic bWluaXBkdi5saW5lYXNmdC5jb20uYnI6QXBwQDIwMjA=",
    "Content-Type": "application/json",
  };

  return headersJson;
};
